$colors: (
  grayscale: $color-grayscale,
  primary: $color-primary,
  secondary: $color-secondary,
  danger: $color-danger,
  success: $color-success,
  info: $color-info,
  darkgray-c1: $color-dark-gray-c1,
);

@mixin button-style($background, $color) {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: $background;
  color: $color;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: darken($background, 10%);
  }

  &:active {
    background-color: darken($background, 10%);
  }

  &:disabled {
    background-color: map-get($colors, "grayscale");
    color: map-get($colors, var(--black));
    cursor: not-allowed;
  }
}

@mixin icon-button-style($background, $icon-color) {
  @include button-style($background, $icon-color);
  width: 40px;
  height: 40px;
  padding: 10px;
}

@mixin rounded-button-style($background, $color) {
  @include button-style($background, $color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin outlined-button-style($border, $background, $color) {
  @include button-style($background, $color);
  border: $border;

  &:hover {
    background-color: $color;
    color: var(--white);
  }
}

@mixin outlined-icon-button-style($border, $background, $color) {
  @include icon-button-style($background, $color);
  border: $border;

  &:hover {
    background-color: $color;
    color: var(--white);
  }
}

@each $color, $value in $colors {
  .button-#{$color} {
    @include button-style($value, var(--white));
  }

  .button-#{$color}-outline {
    @include outlined-button-style(1px solid $value, transparent, $value);
  }

  .icon-button-#{$color} {
    @include icon-button-style($value, var(--white));
  }

  .icon-button-#{$color}-outline {
    @include outlined-icon-button-style(1px solid $value, transparent, $value);
  }

  .rounded-button-#{$color} {
    @include rounded-button-style($value, var(--white));
  }

  .rounded-button-#{$color}-outline {
    @include outlined-button-style(1px solid $value, transparent, $value);
  }
}