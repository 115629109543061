/* You can add global styles to this file, and also import other style files */
@import "./theme/admin-theme.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

html * {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Montserrat", sans-serif;
    color: var(--black);
  }
}
