.font-base-header-1 {
  font-size: 72px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -2px;
}

.font-base-header-2 {
  font-size: 60px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -1.4px;
}

.font-base-header-3 {
  font-size: 48px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -1px;
}

.font-base-header-4 {
  font-size: 36px;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -0.8px;
}

.font-base-header-5 {
  font-size: 30px;
  line-height: 121%;
  font-weight: 600;
  letter-spacing: 0px;
}

.font-base-header-6 {
  font-size: 24px;
  line-height: 128%;
  font-weight: 600;
  letter-spacing: 0px;
}

.font-base-header-7 {
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  letter-spacing: 0px;
}

.font-base-subtitle-1 {
  font-size: 30px;
  line-height: 128%;
  font-weight: 500;
  letter-spacing: 0px;
}

.font-base-subtitle-2 {
  font-size: 24px;
  line-height: 141%;
  font-weight: 700;
  letter-spacing: 0px;
}

.font-base-subtitle-3 {
  font-size: 20px;
  line-height: 141%;
  font-weight: 500;
  letter-spacing: 0px;
}

/* Subtitle 4 */
.font-base-subtitle-4 {
  font-size: 20px;
  line-height: 141%;
  font-weight: 400;
  letter-spacing: 0px;
}

.font-base-copy-bold-l {
  font-size: 18px;
  line-height: 160%;
  font-weight: 600;
  letter-spacing: 0px;
}

.font-base-copy-body-l {
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  letter-spacing: 0px;
}

/* Bold M */
.font-base-copy-bold-m {
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0px;
}

/* Body M */
.font-base-copy-body-m {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: 0px;
}

/* Bold S */
.font-base-copy-bold-s {
  font-size: 14px;
  line-height: 146%;
  font-weight: 600;
  letter-spacing: 0px;
}

/* Body S */
.font-base-copy-body-s {
  font-size: 14px;
  line-height: 146%;
  font-weight: 400;
  letter-spacing: 0px;
}

.font-base-caption-bold {
  font-size: 12px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0.12px;
}

.font-base-caption {
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.font-base-tips {
  font-size: 10px;
  line-height: 141%;
  font-weight: 400;
  letter-spacing: 0.18px;
}

/* OVERLINE */
.font-base-overline {
  font-size: 10px;
  line-height: 146%;
  font-weight: 400;
  letter-spacing: 0.2px;
}

/* Components/Button L */
.font-components-button-l {
  font-size: 18px;
  line-height: 160%;
  font-weight: 500;
  letter-spacing: 0px;
}

/* Components/Button M */
.font-components-button-m {
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0px;
}

/* Components/Button S */
.font-components-button-s {
  font-size: 14px;
  line-height: 146%;
  font-weight: 500;
  letter-spacing: 0px;
}

/* Components/Button XS */
.font-components-button-xs {
  font-size: 12px;
  line-height: 160%;
  font-weight: 500;
  letter-spacing: 0px;
}

.font-base-subtitle-bold {
  font-weight: 700;
  font-size: 20px;
  line-height: 141%;
  letter-spacing: 0px;
}

/* uppercase */
.font-uppercase {
  text-transform: uppercase;
}

/* lowercase */
.font-lowercase {
  text-transform: lowercase;
}

/* capitalize */
.font-capitalize {
  text-transform: capitalize;
}
