.addsubadmin-wrap {
  .label {
    color: var(--black);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
  }

  mat-form-field {
    width: 350px;
    margin-right: 12px;
  }

  textarea {
    width: 100%;
    border-radius: 2px;
  }

  .field-wrap {
    width: 50%;
  }

  mat-card-footer {
    padding: 10px;
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media only screen and (max-width: 1060px) {
    .form-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .field-wrap {
        flex-direction: column;
      }
    }

    mat-form-field {
      width: 280px;
    }
  }

  .save-btn {
    .mat-mdc-raised-button:not(:disabled) {
      background-color: var(--primary);
    }
  }

  .password-validation-wrap {
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 0.333 em;
    font-weight: 500;
    margin-top: -20px;
    margin-left: 15px;
  }
}

.confirmation-wrap {
  .save-btn {
    .mat-mdc-raised-button:not(:disabled) {
      background-color: var(--primary);
    }
  }
  padding: 10px 5px;
}
