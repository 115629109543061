button:not([disabled]) {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-start {
  justify-content: flex-start;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-between-item-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//align items to center
.items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// align item ends
.items-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// item space between 
.items-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}