.profile-content {
  .info-user {
    padding: 0 0 20px 0;

    .image-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 150px;
      top: 155px;
      background: var(--primary);
      border-radius: 50%;
      padding: 4px;

      mat-icon {
        font-size: 18px;
        color: var(--white);
        cursor: pointer;
      }
    }

    .info-top {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      height: 140px;
      position: relative;

      .user-image {
        width: 164px;
        height: 164px;
        border-radius: 200px;
        position: absolute;
        left: 30px;
        top: 30px;
        object-fit: cover;
      }
    }

    .user-header-detail {
      position: absolute;
      left: 215px;
      top: 80px;
    }
    .info-bottom {
      display: flex;
      flex-direction: column;
    }
  }

  .user-content {
    width: 24%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .mat-mdc-form-field {
      width: 100%;
    }
  }

  .password-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;

    .password-content {
      width: 49%;
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .mat-mdc-form-field {
        width: 100%;
      }

      .password-validation-wrap {
        line-height: 20px;
        font-size: 12px;
        letter-spacing: 0.333 em;
        font-weight: 500;
        margin-top: -20px;
        margin-left: 15px;
      }
    }
  }

  .yellow-circle {
    position: absolute;
    width: 281.994px;
    height: 275.13px;
    background: linear-gradient(0deg, transparent 60.9%, var(--primary) 60%);
    border-radius: 50%;
    right: 43px;
    top: 32.8px;
    z-index: 1;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .orange-circle {
    position: absolute;
    width: 281.994px;
    height: 275.13px;
    background: linear-gradient(0deg, transparent 76.9%, var(--secondary) 77%);
    border-radius: 50%;
    right: 185px;
    top: 77px;
  }

  @media screen and (max-width: 1050px) {
    .yellow-circle,
    .orange-circle {
      display: none;
    }

    .flex-wrap {
      display: block;
    }

    .user-content {
      width: auto;
    }

    .password-flex-wrap .password-content {
      width: 100%;
    }

    .password-content {
      width: auto;
    }
  }

  .mat-mdc-text-field-wrapper {
    z-index: 1;
  }

  .mat-mdc-card-content {
    display: block;
    padding: 0 !important;
  }
}
