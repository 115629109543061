.ck-editor__editable_inline {
  max-height: 50vh;
}

.ck-content .image img {
  width: 150px !important;
  height: 150px !important;
}

/* Tiny size */
.text-tiny {
  font-size: 12px;
}

/* Small size */
.text-small {
  font-size: 14px;
}

/* Default size */
.text-default {
  font-size: 16px;
}

/* Big size */
.text-big {
  font-size: 18px;
}

/* Huge size */
.text-huge {
  font-size: 24px;
  font-weight: bold;
}
