$color-white: #ffffff;
$color-black: #101828;
$color-grayscale: #667085;
$color-primary: #ffc107;
$color-secondary: #ff5722;
$color-danger: #bb2124;
$color-light-danger: #f9d0d2;
$color-success: #22bb33;
$color-light-success: #e6f6e9;
$color-warning: #ff9a00;
$color-light-warning: #ffb07426;
$color-info: #5bc0de;
$color-light-dark: #101828d9;
$color-light-gray: #f0f0f0;
$color-dark-gray: #f8f9fd;
$color-grayscale-c1: #d0d5dd;
$color-light-green: #e6f6e9;
$color-light-white: #f9fafb;
$color-grayscale-c2: #8a92a6;
$color-dark-gray-c1: #3d3d3d;
$color-light-silver: #d8d8d8;
$color-grayscale-c3: #f1f1f1;

:root {
  --white: #{$color-white};
  --black: #{$color-black};
  --grayscale: #{$color-grayscale};
  --primary: #{$color-primary};
  --secondary: #{$color-secondary};
  --danger: #{$color-danger};
  --light-danger: #{$color-light-danger};
  --success: #{$color-success};
  --light-success: #{$color-light-success};
  --warning: #{$color-warning};
  --light-warning: #{$color-light-warning};
  --info: #{$color-info};
  --light-dark: #{$color-light-dark};
  --light-gray: #{$color-light-gray};
  --dark-gray: #{$color-dark-gray};
  --grayscale-c1: #{$color-grayscale-c1};
  --light-green: #{$color-light-green};
  --light-white: #{$color-light-white};
  --grayscale-c2: #{$color-grayscale-c2};
  --dark-gray-c1: #{$color-dark-gray-c1};
  --light-silver: #{$color-light-silver};
  --grayscale-c3: #{$color-grayscale-c3};
}

// colors and background-color classes
// declare colors and background color classes for all available color
// for example color:var(--grayscale),class should be grayscale
// for example background-color:var(--grayscale),class should be bg-grayscale
$colors: "white", "black", "grayscale", "primary", "secondary", "danger",
  "alert", "success", "info", "light-gray", "light-dark", "light-success",
  "light-danger", "light-warning", "warning", "dark-gray-c1", "light-silver",
  "grayscale-c2";

@each $color in $colors {
  .#{$color} {
    color: var(--#{$color});
  }

  .bg-#{$color} {
    background-color: var(--#{$color});
  }
}
