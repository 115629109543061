.table-container {
  height: auto;
  max-height: 440px;
  overflow: auto;
  border: 1px solid var(--light-gray);
  border-radius: 8px 10px;
  position: relative;
  z-index: 1;
}

.mat-mdc-no-data-row {
  td {
    text-align: center;
    padding: 15px;
  }
}

.mat-mdc-header-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  letter-spacing: var(--mat-table-header-headline-tracking);
  font-weight: inherit;
  line-height: inherit;
  background: var(--light-white) !important;
  color: var(--grayscale-c2);
}

.mdc-data-table__header-cell {
  padding-left: 12px !important;
}

.mat-mdc-paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  padding: 0 8px;
  flex-wrap: wrap-reverse;
  width: 100%;
  min-height: var(--mat-paginator-container-size);
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background: var(--light-white);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  border: 1px solid var(--grayscale-c1);
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-right: 4px;
}

.mat-mdc-cell {
  padding: 12px 12px !important;
}

.verified-user {
  background-color: var(--light-green);
  padding: 5px;
  border-radius: 4px;
  color: var(--success);
}

.unverified-user {
  background-color: rgba(255, 176, 116, 0.15);
  padding: 5px;
  border-radius: 4px;
  color: var(--primary);
}

.sticky-paginator {
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100%;
}

.search-input {
  mat-form-field {
    width: 320px;
  }
}

.item-center {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}
