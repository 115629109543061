.sidebar-container {
  height: calc(100vh - 80px);
  width: 5rem;
  transition: all 0.3s ease;
  overflow-y: auto;
  z-index: 110;
  margin-top: 5rem;
  position: fixed;
  padding: 0 1.25rem;
  background-color: var(--black);

  &.expand {
    width: 16.375rem;

    .sidebar-items-text {
      opacity: 1;
    }
  }

  &.collapse {
    display: block;

    .sidebar-items-text {
      transition: all 0.2s ease;
      opacity: 0;
    }
  }

  ul {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
    gap: 4px;
    margin-top: 2px;

    li {
      width: 100%;

      .sidebar-items {
        padding: 0.625rem;
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: hidden;
        color: var(--white);
        text-decoration: none;
        white-space: nowrap;
        border-radius: 8px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out;

        .icon-wrapper {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover,
        &.active {
          color: var(--dark);
          background: var(--primary);
          font-weight: 500;
        }
      }
    }
  }
}

.sub-items-wrap {
  & .sub-items {
    margin-left: 30px;
  }

  &.collapse {
    display: none;

    .sidebar-items-text {
      transition: all 0.2s ease;
      opacity: 0;
    }
  }
}

.subIcon-wrap {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .sidebar-container {
    &.collapse {
      transform: translate(-100%);
      width: 16.375rem;
    }
  }
}
