@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.auth-wrapper {
  position: relative;
  width: 100%;
  background-color: var(--white);
  min-height: 100vh;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    height: 2000px;
    width: 2000px;
    top: -10%;
    right: 48%;
    transform: translateY(-50%);
    background-image: linear-gradient(
      -45deg,
      var(--black) 50%,
      var(--secondary) 100%
    );
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }

  .right-panel {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0rem 5rem;
      transition: all 0.2s 0.7s;
      overflow: hidden;
      gap: 12px;

      &.auth-form {
        z-index: 2;

        .action-btn {
          border-radius: 40px;
          padding: 14px 32px;
        }

        .error-wrapper {
          display: flex;
          align-items: center;
          gap: 4px;
          max-width: 380px;
          font-weight: 600;
        }

        .input-field {
          max-width: 380px;
          width: 100%;
          background-color: var(--light-gray);
          height: 50px;
          border-radius: 50px;
          display: grid;
          grid-template-columns: 15% 85%;
          padding: 0 0.4rem;
          position: relative;

          i {
            text-align: center;
            line-height: 50px;
            color: var(--light-dark);
            transition: 0.5s;
            font-size: 1.1rem;
          }

          input {
            background: none;
            outline: none;
            border: none;
            font-size: 1.1rem;
            color: var(--light-dark);

            &::placeholder {
              color: var(--light-dark);
              font-weight: 500;
            }
          }
        }

        .remember-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-width: 380px;

          .remember-input {
            input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              z-index: -1;

              &:checked + span:before {
                content: "\00f046";
              }
            }

            input[type="checkbox"] + span {
              font: 12pt sans-serif;
              color: var(--light-dark);
              display: flex;
              align-items: center;
              gap: 4px;

              &:before {
                font: 16pt FontAwesome;
                content: "\00f096";
                display: inline-block;
                width: 16pt;
              }
            }
          }
        }
      }
    }
  }

  .panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .left-panel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      text-align: center;
      z-index: 6;
      transition: 1.8s ease-in-out;
      transition-delay: 0.4s;

      .content-img {
        width: 500px;
        height: auto;
      }
    }

    .brand-icon {
      position: absolute;
      top: 40px;
    }
  }
}

@media (max-width: 1200px) {
  .auth-wrapper .panels-container .left-panel .content-img {
    width: 400px;
  }
}
@media (max-width: 870px) {
  .auth-wrapper {
    &:before {
      width: 1500px;
      height: 1500px;
      transform: translateX(-50%);
      bottom: 74%;
      left: 50%;
      right: initial;
      top: initial;
      transition: 2s ease-in-out;
    }

    .right-panel {
      width: 100%;
      top: 88%;
      transform: translate(-50%, -100%);
      transition: 1s 0.8s ease-in-out;
      left: 50%;
    }

    .panels-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr 1fr;

      .left-panel {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 30px 8%;
        grid-column: 1 / 2;

        .content-img {
          display: none;
          transition: transform 0.9s ease-in-out;
          transition-delay: 0.6s;
        }
        .brand-icon {
          height: 60px;
          width: auto;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .auth-wrapper {
    padding: 1.5rem;

    &:before {
      bottom: 72%;
      left: 50%;
    }

    .right-panel {
      form {
        padding: 0 1.5rem;
      }
    }

    .panels-container {
      .left-panel {
        .content-img {
          display: none;
        }
      }
    }
  }
}
