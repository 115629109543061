::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dark-gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grayscale);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grayscale);
}