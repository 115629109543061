@mixin font-components-button-xs {
  font-size: 12px !important;
  line-height: 160% !important;
  font-weight: 500 !important;
  letter-spacing: 0px !important;
}

@mixin icon-size {
  mat-icon {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
  }
}

.chip-green {
  background: var(--light-success) !important;
  @include icon-size;

  .mdc-evolution-chip__text-label {
    color: var(--success) !important;
    @include font-components-button-xs;
  }
}

.chip-red {
  background: var(--light-danger) !important;
  @include icon-size;

  .mdc-evolution-chip__text-label {
    color: var(--danger) !important;
    @include font-components-button-xs;
  }
}

.chip-yellow {
  background: var(--light-warning) !important;
  @include icon-size;

  .mdc-evolution-chip__text-label {
    color: var(--warning) !important;
    @include font-components-button-xs;
  }
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 25px !important;
}
