:root {
  // margin classes
  // declare margin classes into 2px to 64px
  // for example margin-left:$value ,class should be ml-{$size)
  $margins: "mt", "mb", "ml", "mr", "mt-mb", "ml-mr", "m";

  @each $margin in $margins {
    @for $i from 0 through 32 {
      // margin top
      @if ($margin== "mt") {
        .#{$margin}-#{$i*2} {
          margin-top: #{$i * 2}px;
        }
      }

      // margin bottom
      @else if ($margin== "mb") {
        .#{$margin}-#{$i*2} {
          margin-bottom: #{$i * 2}px;
        }
      }

      // margin left
      @else if ($margin== "ml") {
        .#{$margin}-#{$i*2} {
          margin-left: #{$i * 2}px;
        }
      }

      // marging right
      @else if($margin== "mr") {
        .#{$margin}-#{$i*2} {
          margin-right: #{$i * 2}px;
        }
      }

      // marging top-bottom
      @else if($margin== "mt-mb") {
        .#{$margin}-#{$i*2} {
          margin-top: #{$i * 2}px;
          margin-bottom: #{$i * 2}px;
        }
      }

      // marging left-right
      @else if($margin== "ml-mr") {
        .#{$margin}-#{$i*2} {
          margin-left: #{$i * 2}px;
          margin-right: #{$i * 2}px;
        }
      }

      // margin
      @else {
        .#{$margin}-#{$i*2} {
          margin: #{$i * 2}px;
        }
      }
    }
  }

  // padding classes
  // declare padding classes into 2px to 64px
  // for example padding-left:$value ,class should be pl-{$size)
  $paddings: "pt", "pb", "pl", "pr", "pt-pb", "pl-pr", "p";

  @each $padding in $paddings {
    @for $i from 0 through 32 {
      // padding top
      @if ($padding== "pt") {
        .#{$padding}-#{$i*2} {
          padding-top: #{$i * 2}px;
        }
      }

      // padding bottom
      @else if ($padding== "pb") {
        .#{$padding}-#{$i*2} {
          padding-bottom: #{$i * 2}px;
        }
      }

      // padding left
      @else if ($padding== "pl") {
        .#{$padding}-#{$i*2} {
          padding-left: #{$i * 2}px;
        }
      }

      // padding right
      @else if($padding== "pr") {
        .#{$padding}-#{$i*2} {
          padding-right: #{$i * 2}px;
        }
      }

      // padding top bottom
      @else if($padding== "pt-pb") {
        .#{$padding}-#{$i*2} {
          padding-top: #{$i * 2}px;
          padding-bottom: #{$i * 2}px;
        }
      }

      // padding left right
      @else if($padding== "pl-pr") {
        .#{$padding}-#{$i*2} {
          padding-left: #{$i * 2}px;
          padding-right: #{$i * 2}px;
        }
      }

      // padding
      @else {
        .#{$padding}-#{$i*2} {
          padding: #{$i * 2}px;
        }
      }
    }
  }

  // width and height classes
  // declare width and height classes from 10% to 100%  with range 10
  // for example width:50% ,class should be width-50
  @for $i from 0 through 100 {
    .width-#{$i} {
      width: #{$i}+ "%";
    }

    .height-#{$i} {
      height: #{$i}+ "%";
    }

    .gap-#{$i} {
      gap: #{$i}+ "%";
    }
  }

  // text ellipsis
  .text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  //position absolute
  .p-absolute {
    position: absolute;
  }

  //text align center
  .text-center {
    text-align: center;
  }

  //position relative
  .p-relative {
    position: relative;
  }

  //cursor pointer
  .cursor-pointer {
    cursor: pointer;
  }

  //cursor not-allowed
  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .show-desktop {
    display: block;
  }

  .show-mobile {
    display: none;
  }

  .m-auto {
    margin: auto;
  }

  .p-auto {
    padding: auto;
  }

  @media only screen and (max-width: 768px) {
    .show-desktop {
      display: none;
    }

    .show-mobile {
      display: block;
    }
  }
}
