@use "@angular/material" as mat;

@include mat.core();

@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

$yellow-palette: (
  50: #fff8e1,
  100: #ffecb3,
  200: #ffe082,
  300: #ffd54f,
  400: #ffca28,
  500: #ffc107,
  600: #ffb300,
  700: #ffa000,
  800: #ff8f00,
  900: #ff6f00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #ffe57f,
    A200: #ffd740,
    A400: #ffc400,
    A700: #ffab00,
  ),
);

$red-palette: (
  50: #fbe9e7,
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #ff5722,
  600: #f4511e,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: #ff9e80,
    A200: #ff6e40,
    A400: #ff3d00,
    A700: #dd2c00,
  ),
);

$custom-primary: mat.define-palette($yellow-palette);
$custom-secondary: mat.define-palette($red-palette);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-secondary,
    ),
  )
);

@include mat.all-component-themes($custom-theme);
